export const ProjectOtherProjects = (props) => {
    return (
        <section className="project-other-projects">
            <div className="project-other-projects__title-container">
                <p className="typography typography__body-s">Другие проекты</p>
            </div>

            <div className="main-projects__project-content-container-second">
                <div className="main-projects__project-container main-projects__project-container_type_fourth">
                    <img className="main-projects__image main-projects__cursor" src={props.imageFirst} alt={props.nameFirst} onClick={props.openPageFirst} />

                    <div className="main-projects__text-container main-projects__text-container_type_margin-first">
                        <p className="typography typography__body-m main-projects__cursor" onClick={props.openPageFirst}>{props.textFirst}</p>

                        <button className="main-projects__project-button" onClick={props.openPageFirst}>
                            <span className="typography typography__body-s">{props.nameFirst}</span>
                        </button>
                    </div>
                </div>

                <div className="main-projects__project-container main-projects__project-container_type_third">
                    <img className="main-projects__image main-projects__cursor" src={props.imageSecond} alt={props.nameSecond} onClick={props.openPageSecond} />

                    <div className="main-projects__text-container main-projects__text-container_type_margin-second">
                        <p className="typography typography__body-m main-projects__cursor" onClick={props.openPageSecond}>{props.textSecond}</p>

                        <button className="main-projects__project-button" onClick={props.openPageSecond}>
                            <span className="typography typography__body-s">{props.nameSecond}</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}