import { Fragment } from "react";

export const TextProjects = (props) => {
    return (
        <div className="title-text">
            <div className="title-text__container">
                {props.text.map((item) => (
                    <Fragment key={item}>
                        <p className="typography typography__body-l">{item}</p>
                    </Fragment>
                ))}
            </div>
        </div>
    );
}
