import { Logo } from "../Logo/Logo";

export const Header = (props) => {
    const openMainPage = () => {
        props.history.push('/');
    }

    function openTelegram() {
        window.open('https://t.me/nikitina_alexa');
    }

    return (
        <section className="header">
            <div className="header__content">
                <div className="header__logo-container" onClick={openMainPage}>
                    <Logo />
                </div>

                {/* <div className="header__link-container_type_start header__link-visible">
                    <button to='#' className="header__link typography typography__body-s">О студии</button>
                </div>

                <div className="header__couple-link header__link-visible">
                    <div className="header__link-container">
                        <button to='#' className="header__link typography typography__body-s">Кейсы</button>
                    </div>
                    <div className="header__link-container">
                        <button to='#' className="header__link typography typography__body-s">Сервисы</button>
                    </div>
                </div>

                <div className="header__link-container_type_end header__link-visible">
                    <button to='#' className="header__link typography typography__body-s">Контакты</button>
                </div> */}

                <div className="header__link-container_type_end">
                    <button onClick={openTelegram} className="header__link header__link_type_with-circle">
                        <span className="typography typography__body-s">Обсудить проект</span>

                        {/* <div className="header__circle" />

                        <div className="header__burger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 13.7466L30 13.7466V12.5L2 12.5V13.7466ZM2 19.5L30 19.5V18.2534L2 18.2534V19.5Z" fill="#1D2023"/>
                            </svg>
                        </div> */}
                    </button>
                </div>
            </div>
        </section>
    );
}
