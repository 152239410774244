export const LogoFull = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1384 182" fill="none">
            <path d="M652.848 17.5113C652.848 7.83595 660.618 0 670.212 0C679.806 0 687.577 7.83595 687.577 17.5113C687.577 27.1867 679.806 35.0227 670.212 35.0227C660.618 35.0227 652.848 27.1867 652.848 17.5113Z" fill="#1D2023"/>
            <path d="M747.461 145.243V73.4322H784.014V50.7521H747.461V14.6258H720.411V50.7705H696.28V73.4506H720.411V146.881C720.411 165.256 725.464 180.156 753.407 180.156H787.37V157.494H759.572C750.088 157.494 747.443 152.013 747.443 145.262L747.461 145.243Z" fill="#1D2023"/>
            <path d="M683.762 50.7548H656.694V180.14H683.762V50.7548Z" fill="#1D2023"/>
            <path d="M168.373 50.7528L112.96 143.865V50.7528H78.8329L0 180.138H30.0229L85.8918 86.2721V180.138H119.563L175.432 86.2721V180.138H202.5V50.7528H168.373Z" fill="#1D2023"/>
            <path d="M440.169 50.7521H472.016L419.248 104.611L473.877 180.137H441.355L401.227 123.023L379.649 145.023V180.137H352.6V14.6258H379.649V112.373L440.169 50.7521Z" fill="#1D2023"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M531.054 49.2428C492.276 49.2428 463.585 76.3376 463.585 115.444C463.585 154.55 492.276 181.663 531.054 181.663C562.153 181.663 589.076 165.053 595.66 137.167H568.027C562.828 152.803 546.376 159.756 531.054 159.756C510.918 159.756 494.885 144.819 492.094 122.507H598.36C598.469 120.797 598.524 117.21 598.524 115.444C598.524 76.3376 569.851 49.2428 531.054 49.2428ZM531.054 71.1504C549.367 71.1504 564.251 83.4745 568.92 102.439H493.188C497.858 83.4745 512.76 71.1504 531.036 71.1504H531.054Z" fill="#1D2023"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M217.525 90.1701C217.525 78.6744 227.975 49.2466 272.966 49.2428C317.974 49.2462 331.415 72.5868 331.415 95.9826V180.136H304.347V160.087C296.741 169.284 282.04 181.645 260.608 181.645C230.257 181.645 213.804 167.095 213.804 141.472C213.804 109.742 247.347 102.421 267.065 102.421H304.365V95.9826C304.365 74.5349 285.031 71.224 274.47 71.224C263.909 71.224 245.578 74.6269 244.082 90.1701H217.525ZM241.328 141.49C241.328 155.414 253.658 160.436 265.131 160.436V160.455C281.109 160.455 301.885 151.68 304.365 133.709V122.526H268.889C254.06 122.526 241.328 127.566 241.328 141.49Z" fill="#1D2023"/>
            <path d="M1022.02 49.7773L966.152 143.662V49.7773H932.482L876.613 143.662V49.7773H849.545V179.162H883.69L939.103 86.0507V179.162H973.229L1052.04 49.7773H1022.02Z" fill="#1D2023"/>
            <path d="M1297.15 143.662L1353.02 49.7773H1383.05L1304.21 179.162H1270.09V86.0507L1214.67 179.162H1180.55V49.7773H1207.62V143.662L1263.48 49.7773H1297.15V143.662Z" fill="#1D2023"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1102.3 48.2673C1063.53 48.2673 1034.83 75.362 1034.83 114.468C1034.83 153.574 1063.53 180.688 1102.3 180.688C1141.08 180.688 1169.77 153.593 1169.77 114.468C1169.77 75.3436 1141.1 48.2673 1102.3 48.2673ZM1102.3 157.437C1080.11 157.437 1062.91 139.852 1062.91 114.468C1062.91 89.0841 1080.11 71.5176 1102.3 71.5176C1124.5 71.5176 1141.72 89.1025 1141.72 114.468C1141.72 139.834 1124.52 157.437 1102.3 157.437Z" fill="#1D2023"/>
            <path d="M1348.32 163.149C1348.32 153.474 1356.09 145.638 1365.69 145.638C1375.28 145.638 1383.05 153.474 1383.05 163.149C1383.05 172.824 1375.28 180.66 1365.69 180.66C1356.09 180.66 1348.32 172.824 1348.32 163.149Z" fill="#1D2023"/>
        </svg>
    );
}