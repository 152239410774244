import { Fragment } from "react";

export const HeadingInfo = (props) => {
    return (
        <section className="project-heading-info">
            <h1 className="typography typography__h1">{props.name}</h1>

            <div className="project-heading-info__info-container">
                <div className="project-heading-info__client-info">
                    <div className="project-heading-info__text-container">
                            <p className="typography typography__body-s">Клиент</p>
                    </div>

                    <div className="project-heading-info__list project-heading-info__list_type_with-gap">
                        {props.client.map((item) => (
                            <Fragment key={item}>
                                <p className="typography typography__body-s">{item}</p>
                            </Fragment>
                        ))}
                    </div>
                </div>

                <div className="project-heading-info__profile-info">
                    <div className="project-heading-info__text-container">
                        <p className="typography typography__body-s">Профиль</p>
                    </div>

                    <div className="project-heading-info__list">
                        {props.profile.map((item) => (
                            <Fragment key={item}>
                                <p className="typography typography__body-s">{item}</p>
                            </Fragment>
                        ))}
                    </div>
                </div>

                <div className="project-heading-info__date-info">
                    <p className="project-heading-info__date-info_type_typography typography typography__body-s">Год</p>
                    <p className="typography typography__body-s">{props.date}</p>
                </div>
            </div>
        </section>
    );
}