import { HeadingInfo } from "../../Project/HeadingInfo/HeadingInfo";
import { Image } from "../../Image/Image";
import { AboutProject } from "../../Project/AboutProject/AboutProject";
import { TitleTextProjects } from "../../Project/TitleTextProjects/TitleTextProjects";
import { TextProjects } from "../../Project/TextProjects/TextProjects";
import { ProjectOtherProjects } from "../../Project/ProjectOtherProjects/ProjectOtherProjects";

import imageBigFirst from "../../../images/dataspike-big-first.jpg";
import imageBigSecond from "../../../images/dataspike-big-second.jpg";
import imageBigThird from "../../../images/dataspike-big-third.jpg";
import imageBigFourth from "../../../images/dataspike-big-fourth.jpg";
import imageBigFifth from "../../../images/dataspike-big-fifth.jpg";
import imageBigSixth from "../../../images/dataspike-big-sixth.jpg";
import imageBigSeventh from "../../../images/dataspike-big-seventh.jpg";
import imageBigEighth from "../../../images/dataspike-big-eighth.jpg";
import imageBigNinth from "../../../images/dataspike-big-ninth.jpg";

import imageMediumFirst from "../../../images/dataspike-medium-first.jpg";
import imageMediumSecond from "../../../images/dataspike-medium-second.jpg";
import imageMediumThird from "../../../images/dataspike-medium-third.jpg";
import imageMediumFourth from "../../../images/dataspike-medium-fourth.jpg";
import imageMediumFifth from "../../../images/dataspike-medium-fifth.jpg";
import imageMediumSixth from "../../../images/dataspike-medium-sixth.jpg";
import imageMediumSeventh from "../../../images/dataspike-medium-seventh.jpg";
import imageMediumEighth from "../../../images/dataspike-medium-eighth.jpg";

import myBusiness from "../../../images/crpp-big-first.jpg";
import trice from "../../../images/main-projects-trice.jpg";

export const DataSpike = (props) => {
    const openCrppPage = () => {
        props.history.push('/projects/crpp');
    }

    const openTricePage = () => {
        props.history.push('/projects/trice');
    }

    return (
        <div className="project">
            <HeadingInfo
                name={'DataSpike'}
                client={['DataSpike', 'AML & KYC решения']}
                profile={['Брендинг', 'Платформа', 'Сайт', 'Коммуникации']}
                date={'2020–н.в.'}
            />

            <Image
                image={imageBigFirst}
                alt={'DataSpike'}
            />

            <AboutProject
                text={'Dataspike — платформа на базе искусственного интелекта для верификации и проверки персональных данных.'}
            />

            <section className="project-task">
                <TitleTextProjects
                    title={'Проект и задача'}
                    text={['Изначально команда Data Spike обратилась к нам за разработкой MVP платформы и брендинга. В дальнейшем продукт начал расти и развиваться, а мы продолжили работу, создавая новый функционал на основе нужд бизнеса и ожиданий пользователей.']}
                />

                <div className="project__couple-images">
                    <Image
                        image={imageMediumFirst}
                        alt={'DataSpike'}
                    />

                    <Image
                        image={imageMediumSecond}
                        alt={'DataSpike'}
                    />
                </div>
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Брендинг'}
                    text={['Логотип Dataspike воплощает в себе концепцию точности, гибкости и динамики. Эти идеи нашли отражение в его дизайне: внутри буквы «D» скрывается градиентная галочка, наклоненная вправо, которая создает ощущение плавного движения вперед.', 'Этот элемент символизирует успешную проверку — ключевую задачу, которую решает компания. Таким образом, логотип становится выражением миссии Dataspike, отражая стремление к эффективным и надежным решениям в области верификации.']}
                />

                <div>
                    <Image
                        image={imageBigSecond}
                        alt={'DataSpike'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumThird}
                            alt={'DataSpike'}
                        />

                        <Image
                            image={imageMediumFourth}
                            alt={'DataSpike'}
                        />
                    </div>
                </div>
            </section>

            <section className="project-task_type_border-none">
                <TitleTextProjects
                    title={'Платформа'}
                    text={['Продукт с нуля', 'Функционал MVP включал в себя проверку людей и компаний по обширным базам данных на наличие санкций. Мы полностью спроектировали все ключевые сценарии сервиса.', 'После запуска MVP компании удалось привлечь значительные инвестиции и тем самым продолжить нашу работу по развитию платформы, тестируя уже существующий функционал и добавляя новый.']}
                />

                <div>
                    <Image
                        image={imageBigThird}
                        alt={'DataSpike'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumFifth}
                            alt={'DataSpike'}
                        />

                        <Image
                            image={imageMediumSixth}
                            alt={'DataSpike'}
                        />
                    </div>

                    <Image
                        image={imageBigFourth}
                        alt={'DataSpike'}
                    />
                </div>
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Верификация личности', 'Одна из основных особенностей сервиса — процедура KYC (know your client или «знай своего клиента»). Она проходит в несколько этапов, включая проверку по распознаванию лица. Благодаря понятному интерфейсу и инструкциям, такая проверка занимает меньше минуты.', 'При этом, функционал такой проверки можно формировать под себя, добавляя нужные поля или запрашивая только те документы, которые интересуют конкретную компанию.']}
                />

                <div>
                    <Image
                        image={imageBigFifth}
                        alt={'DataSpike'}
                    />

                    <Image
                        image={imageBigSixth}
                        alt={'DataSpike'}
                    />
                </div>
            </section>

            <section className="project-task project-task_type_padding-top">
                <TextProjects
                    text={['Профиль аппликанта', 'Функционал платформы -- уже не просто поиск людей, но и создание новых профилей, и редактирование уже существующих.', 'Пользователи сервиса могут добавлять данные и фото, обеспечивая актуальность и точность информации в любой момент. Также можно отследить историю проверок и скачать PDF-репорт.']}
                />

                <Image
                    image={imageBigSeventh}
                    alt={'DataSpike'}
                />
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Сайт'}
                    text={['Все о продукте', 'Для продвижения сервиса мы создали сайт, который служит центральной точкой для демонстрации всех возможностей Data Spike.', 'Главная цель — упростить понимание того, как работает платформа, какие задачи она решает и как она может быть полезна в конкретных задачах потенциальных клиентов.']}
                />

                <Image
                    image={imageBigEighth}
                    alt={'DataSpike'}
                />
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Коммуникации'}
                    text={['Айдентика в действии', 'Обновили визуальную коммуникацию бренда: разработали макеты для баннеров, мерч, обложки для соцсетей и презентации для мероприятий и инвесторов.']}
                />

                <div>
                    <div className="project__couple-images">
                        <Image
                            image={imageMediumSeventh}
                            alt={'DataSpike'}
                        />

                        <Image
                            image={imageMediumEighth}
                            alt={'DataSpike'}
                        />
                    </div>

                    <Image
                        image={imageBigNinth}
                        alt={'DataSpike'}
                    />
                </div>
            </section>

            <ProjectOtherProjects
                imageFirst={myBusiness}
                nameFirst={'Мой Бизнес'}
                textFirst={'Портал по развитию малого и среднего бизнеса Санкт Петербурга'}
                openPageFirst={openCrppPage}
                imageSecond={trice}
                nameSecond={'Trice'}
                textSecond={'Брендинг для международной линии средств по уходу за кожей'}
                openPageSecond={openTricePage}
            />
        </div>
    );
}