export const AboutProject = (props) => {
    return (
        <section className="project-about">
            <div className="project-about__typography-grid-container">
                <div className="project-about__typography-grid-block"/>

                <h2 className="typography typography__h2">{props.text}</h2>
            </div>
        </section>
    );
}