import { LogoFull } from "../Logo/LogoFull";
import showreelImage from "../../images/main-showreel.jpg";
import showreel from "../../video/showreel-mini.mp4";
import myBusiness from "../../images/crpp-big-first.jpg";
import trice from "../../images/main-projects-trice.jpg";
import goldenEgg from "../../images/main-projects-golden-egg.jpg";
import dataSpike from "../../images/dataspike-big-first.jpg";
import avatarAlexa from "../../images/avatar-alexa.jpg";
import avatarAsya from "../../images/avatar-asya.jpg";
import avatarAnna from "../../images/avatar-anna.jpg";
import avatarSasha from "../../images/avatar-sasha.jpg";
import avatarLaura from "../../images/avatar-laura.jpg";
import avatarJulia from "../../images/avatar-julia.jpg";
import avatarNikita from "../../images/avatar-nikita.jpg";
import avatarKseniya from "../../images/avatar-kseniya.jpg";

export const Main = (props) => {
    function accordion(id) {
        const text = document.getElementById(id).querySelector('.main-services__service-text');

        text.classList.toggle('main-services__service-text_type_active');
    }

    function event(e) {
            const showreelImage = document.querySelector('.main-main-screen__showreel');
            const x = e.clientX;
            const y = e.clientY;
            
            showreelImage.style.left = x + -113 + 'px';
            showreelImage.style.top = y + -70 + 'px';
    }

    const mouseOver = () => {
        const container = document.querySelector('.main-main-screen__logo-container');
        const showreelImage = document.querySelector('.main-main-screen__showreel');

        container.addEventListener('mousemove', event);

        showreelImage.style.opacity = 1;
    }

    const mouseOut = () => {
        const container = document.querySelector('.main-main-screen__logo-container');
        // const showreelImage = document.querySelector('.main-main-screen__showreel');

        container.removeEventListener('mousemove', event);

        // showreelImage.style.opacity = 0;
    }

    function scrollToShowreel() {
        document.querySelector('.main-showreel').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    function mainScreenScroll() {
        // document.addEventListener('scroll', scrollToShowreel);
        // const mainScreen = document.querySelector('.main-main-screen');
        // mainScreen.addEventListener('scroll', scrollToShowreel);
        // console.log(mainScreen);
    }

    function mainScreenNoScroll() {
        // document.removeEventListener('scroll', scrollToShowreel);
    }

    const openCrppPage = () => {
        props.history.push('/projects/crpp');
    }

    const openTricePage = () => {
        props.history.push('/projects/trice');
    }

    const openGoldenEggPage = () => {
        props.history.push('/projects/golden-egg');
    }

    const openDataspikePage = () => {
        props.history.push('/projects/dataspike');
    }

    return (
        <div className="main">
            <section className="main-main-screen" onMouseOver={mainScreenScroll} onMouseOut={mainScreenNoScroll}>
                <div className="main-main-screen__logo-content">
                    <div id="container" className="main-main-screen__logo-container" onMouseOver={mouseOver} onMouseOut={mouseOut}>
                        <LogoFull />
                    </div>
                    <button className="main-main-screen__showreel" onClick={scrollToShowreel} />
                </div>

                <div className="main-main-screen__text-container">
                    <p className="typography typography__body-m">Мы исследуем, проектируем и запускаем восхитительные цифровые  продукты.</p>
                </div>
            </section>

            <section className="main-showreel">
                <div className="main-showreel__video-container">
                    <video className="main-showreel__video" autoPlay muted loop poster={showreelImage} playsInline>
                        <source src={showreel} type="video/mp4" />
                    </video>
                </div>
            </section>

            <section className="main-experience">
                <div className="main-experience__text-container">
                    <div className="project-about__typography-grid-container">
                        <div className="project-about__typography-grid-block"/>

                        <h2 className="typography typography__h2">Создаем WOW продукты для компаний на этапе запуска и активного роста, улучшаем метрики и связь с аудиторией.</h2>
                    </div>
                </div>

                <div className="main-experience__container">
                    <div className="main-experience__number-container">
                        <div className="main-experience__number-text-container">
                            <p className="typography typography__number">15</p>
                            <p className="typography typography__body-s">Лет опыта</p>
                        </div>
                    </div>

                    <div className="main-experience__info-content">
                        <div>
                            <p className="typography typography__h2">25М</p>
                            <p className="typography typography__body-s">активная клиентская аудитория</p>
                        </div>

                        <div>
                            <p className="typography typography__h2">300+</p>
                            <p className="typography typography__body-s">реализованных проектов</p>
                        </div>

                        <div>
                            <p className="typography typography__h2">$50М</p>
                            <p className="typography typography__body-s">инвестиции, привлеченные нашими клиентами</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="main-projects">
                <div className="main-projects__content">
                    <div className="main-projects__span-container">
                        <span className="typography typography__body-s">Проекты (12)</span>
                        {/* <span className="typography typography__body-s">Последние проекты</span> */}
                    </div>

                    <div className="main-projects__content-container">
                        <div className="main-projects__project-content-container-first">
                            <div className="main-projects__project-container main-projects__project-container_type_first">
                                <img className="main-projects__image main-projects__cursor" src={myBusiness} alt="Мой Бизнес" onClick={openCrppPage} />

                                <div className="main-projects__text-container">
                                    <p className="typography typography__body-m main-projects__cursor" onClick={openCrppPage}>Портал по развитию малого и среднего бизнеса Санкт Петербурга</p>

                                    <button className="main-projects__project-button" onClick={openCrppPage}>
                                        <span className="typography typography__body-s">Мой Бизнес</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="main-projects__project-content-container-second">
                            <div className="main-projects__project-container main-projects__project-container_type_third">
                                <img className="main-projects__image main-projects__cursor" src={trice} alt="Trice" onClick={openTricePage} />

                                <div className="main-projects__text-container main-projects__text-container_type_margin-left">
                                    <p className="typography typography__body-m main-projects__cursor" onClick={openTricePage}>Брендинг для международной линии средств по уходу за кожей</p>

                                    <button className="main-projects__project-button" onClick={openTricePage}>
                                        <span className="typography typography__body-s">Trice</span>
                                    </button>
                                </div>
                            </div>

                            <div className="main-projects__project-container main-projects__project-container_type_fourth">
                                <img className="main-projects__image main-projects__cursor" src={goldenEgg} alt="Golden Egg" onClick={openGoldenEggPage} />

                                <div className="main-projects__text-container main-projects__text-container_type_margin-left">
                                    <p className="typography typography__body-m main-projects__cursor" onClick={openGoldenEggPage}>Упаковали под ключ фудтех приложение для гастрономических гедонистов</p>

                                    <button className="main-projects__project-button" onClick={openGoldenEggPage}>
                                        <span className="typography typography__body-s">Golden Egg</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="main-projects__project-content-container-third">
                            <div className="main-projects__project-container main-projects__project-container_type_second">
                                <img className="main-projects__image main-projects__cursor" src={dataSpike} alt="Data Spike" onClick={openDataspikePage} />

                                <div className="main-projects__text-container">
                                    <p className="typography typography__body-m main-projects__cursor" onClick={openDataspikePage}>Платформа для KYC верификации и AML-решений: клиенты доверяют нам уже 4 года на всех этапах роста</p>

                                    <button className="main-projects__project-button" onClick={openDataspikePage}>
                                        <span className="typography typography__body-s">Data Spike</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="main-projects__project-content-container-second">
                            <div className="main-projects__project-container main-projects__project-container_type_fourth">
                                <img className="main-projects__image" src={goMining} alt="Go Mining" />

                                <div className="main-projects__text-container main-projects__text-container_type_margin-left">
                                    <p className="typography typography__body-m">Промо-сайт компании в сфере майнинга и аренды криптоферм на базе Биткоина</p>

                                    <button className="main-projects__project-button">
                                        <span className="typography typography__body-s">Go Mining</span>
                                    </button>
                                </div>
                            </div>

                            <div className="main-projects__project-container main-projects__project-container_type_third">
                                <img className="main-projects__image" src={revhub} alt="RevHub" />

                                <div className="main-projects__text-container">
                                    <p className="typography typography__body-m">Сервис сбора отзывов с маркетплейсов Яндекс Маркет, Wildberries и Ozon</p>

                                    <button className="main-projects__project-button">
                                        <span className="typography typography__body-s">RevHub</span>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* <button className="main-projects__all-projects-button">
                    <span className="typography typography__body-l">Все проекты</span>
                    <span className="typography typography__body-s main-projects__all-projects-button-span">(12)</span>
                </button> */}
            </section>

            {/* <section className="main-services">
                <div>
                    <span className="typography typography__body-s">Сервисы</span>
                </div>

                <h2 className="main-services__typography-grid-first typography typography__h2">Wow подход за рамками</h2>

                <h2 className="main-services__typography-grid-second typography typography__h2">эстетики. Наша цель — помочь бизнесу достичь результатов.</h2>

                <div className="main-services__content">
                    <div>
                        <div className="main-services__service" id='service-fintech'>
                            <input className="main-services__checkbox" type="checkbox" id='fintech' onClick={() => accordion('service-fintech')}></input>

                            <label className="main-services__service-heading" htmlFor='fintech'>
                                <span className="main-services__service-number typography typography__body-s">01</span>
                                <h3 className="typography typography__body-l">Финтех</h3>
                            </label>

                            <p className="main-services__service-text typography typography__body-m">Финтех Финтех Финтех Финтех Финтех Финтех Финтех</p>
                        </div>

                        <div className="main-services__service" id='service-websites'>
                            <input className="main-services__checkbox" type="checkbox" id='websites' onClick={() => accordion('service-websites')}></input>

                            <label className="main-services__service-heading" htmlFor='websites'>
                                <span className="main-services__service-number typography typography__body-s">02</span>
                                <h3 className="typography typography__body-l">Сайты</h3>
                            </label>

                            <p className="main-services__service-text typography typography__body-m">Сайты Сайты Сайты Сайты Сайты Сайты Сайты</p>
                        </div>

                        <div className="main-services__service" id='service-mobile-apps'>
                            <input className="main-services__checkbox" type="checkbox" id='mobile-apps' onClick={() => accordion('service-mobile-apps')}></input>

                            <label className="main-services__service-heading" htmlFor='mobile-apps'>
                                <span className="main-services__service-number typography typography__body-s">03</span>
                                <h3 className="typography typography__body-l">Приложения</h3>
                            </label>

                            <p className="main-services__service-text typography typography__body-m">Приложения Приложения Приложения Приложения Приложения Приложения Приложения</p>
                        </div>

                        <div className="main-services__service" id='service-branding'>
                            <input className="main-services__checkbox" type="checkbox" id='branding' onClick={() => accordion('service-branding')}></input>

                            <label className="main-services__service-heading" htmlFor='branding'>
                                <span className="main-services__service-number typography typography__body-s">04</span>
                                <h3 className="typography typography__body-l">Брендинг</h3>
                            </label>

                            <p className="main-services__service-text typography typography__body-m">Брендинг Брендинг Брендинг Брендинг Брендинг Брендинг Брендинг</p>
                        </div>

                        <div className="main-services__service" id='service-communications'>
                            <input className="main-services__checkbox" type="checkbox" id='communications' onClick={() => accordion('service-communications')}></input>
                            
                            <label className="main-services__service-heading" htmlFor='communications'>
                                <span className="main-services__service-number typography typography__body-s">05</span>
                                <h3 className="typography typography__body-l">Коммуникации</h3>
                            </label>

                            <p className="main-services__service-text typography typography__body-m">Коммуникации Коммуникации Коммуникации Коммуникации Коммуникации Коммуникации Коммуникации</p>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="main-team">
                <div className="main-team__quote">
                    <div className="project-about__typography-grid-container">
                        <div className="project-about__typography-grid-block"/>

                        <h2 className="typography typography__h2">Люди наша главная ценность. Познакомьтесь с самой поддерживающей и уютной командой, которая понимает задачу с полуслова.</h2>
                    </div>
                </div>

                <div className="main-team__team-content">
                    <div className="main-team__span-container">
                        <span className="typography typography__body-s">Команда</span>
                        <span className="typography typography__body-s">Наша главная ценность — это люди</span>
                    </div>

                    <div className="main-team__team-container">
                        <div className="main-team__card main-team__card_type_alexa">
                            <img className="main-projects__image" src={avatarAlexa} alt="CEO" />
                            <div>
                                <p className="typography typography__body-s">Александра Вдовыдченко</p>
                                <p className="main-team__typography typography typography__body-s">CEO</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_asya">
                            <img className="main-projects__image" src={avatarAsya} alt="Арт-директор" />
                            <div>
                                <p className="typography typography__body-s">Ася Якунина</p>
                                <p className="main-team__typography typography typography__body-s">Арт-директор</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_anna">
                            <img className="main-projects__image" src={avatarAnna} alt="Дизайнер" />
                            <div>
                                <p className="typography typography__body-s">Анна Дерюгина</p>
                                <p className="main-team__typography typography typography__body-s">Дизайнер</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_sasha">
                            <img className="main-projects__image" src={avatarSasha} alt="Дизайнер" />
                            <div>
                                <p className="typography typography__body-s">Александра Лариева</p>
                                <p className="main-team__typography typography typography__body-s">Дизайнер</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_laura">
                            <img className="main-projects__image" src={avatarLaura} alt="Дизайнер" />
                            <div>
                                <p className="typography typography__body-s">Лаура Стрелка</p>
                                <p className="main-team__typography typography typography__body-s">Дизайнер</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_julia">
                            <img className="main-projects__image" src={avatarJulia} alt="Дизайнер" />
                            <div>
                                <p className="typography typography__body-s">Юлия Байкеева</p>
                                <p className="main-team__typography typography typography__body-s">Дизайнер</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_nikita">
                            <img className="main-projects__image" src={avatarNikita} alt="Front-end разработчик" />
                            <div>
                                <p className="typography typography__body-s">Никита Вдовыдченко</p>
                                <p className="main-team__typography typography typography__body-s">Front-end разработчик</p>
                            </div>
                        </div>

                        <div className="main-team__card main-team__card_type_kseniya">
                            <img className="main-projects__image" src={avatarKseniya} alt="Менеджер по развитию" />
                            <div>
                                <p className="typography typography__body-s">Ксения Зисерман</p>
                                <p className="main-team__typography typography typography__body-s">Менеджер по развитию</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
